import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2021 = () => (
  <Layout
    title="3M 2021 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2021 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2021 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: '9f0ed752-e513-46f5-af36-16fe6fc5bee3',
                    poster: '2021-finalist-thumb-aadrit-talukdar.png',
                    title: '3M 2021 YSC Finalist Presentation: Aadrit Talukdar',
                    description:
                      'Meet Aadrit. Aadrit developed a scalable and reusable shipping box that can be used for online order shipments to reduce or eliminate the use of oversized boxes and excess packing materials.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '5147c866-f563-45cb-9d97-1ff28f82cae3',
                    poster: '2021-finalist-thumb-danielle-steinbeck.png',
                    title:
                      '3M 2021 YSC Finalist Presentation: Danielle Steinbeck',
                    description:
                      'Meet Danielle. Danielle used deep neural networks to more accurately diagnose and treat neurological disorders and brain diseases.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '471fdbfd-d077-4888-bda5-d200df5cd74f',
                    poster: '2021-finalist-thumb-moitri-santra.png',
                    title: '3M 2021 YSC Finalist Presentation: Moitri Santra',
                    description:
                      'Meet Moitri. Moitri developed a novel optimized gel composed of Chitosan and Citric acid to induce algae coagulation and settlement in Harmful Algal Bloom (HAB) effected bodies of water to reduce the suffocation and death of animal life and toxins that could lead to health problems for humans.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '0bd8934a-bc0d-4326-a99f-afac06fb249b',
                    poster: '2021-finalist-thumb-abhinav-anne.png',
                    title: '3M 2021 YSC Finalist Presentation: Abhinav Anne',
                    description:
                      'Meet Abhinav. Abhinav developed a way to extend the safety and shelf-life of blood products with Nitric Oxide producing Polyurethane bag.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '718b1450-77b9-47c9-b180-467bdf5229a7',
                    poster: '2021-finalist-thumb-snigtha-mohanraj.png',
                    title:
                      '3M 2021 YSC Finalist Presentation: Snigtha Mohanraj',
                    description:
                      'Meet Snigtha. Snigtha discovered Ferro-Sponge, a novel way to remove microplastics and oil from contaminated water.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '447b079c-40c7-4b99-8c2f-4418b3e0d3fd',
                    poster: '2021-finalist-thumb-samartha-mahapatra.png',
                    title:
                      '3M 2021 YSC Finalist Presentation: Samarth Mahapatra',
                    description:
                      'Meet Samarth. Samarth deployed edge computing and advance vision algorithms to help people with vision impairments cook with ease.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '25c8540c-912a-4bde-bb20-1aac82487a27',
                    poster: '2021-finalist-thumb-sarah-park.jpg',
                    title: '3M 2021 YSC Finalist Presentation: Sarah Park',
                    description:
                      'Meet Sarah. Sarah developed Spark Care+, a personalized music therapy treatment for mental health disorders using artificial intelligence (AI), galvanic skin response (GSR), and photoplethysmography (PPG).',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'eb4861cf-728f-4ae6-bd32-91fd4ce4f423',
                    poster: '2021-finalist-thumb-sidney-zhang.png',
                    title: '3M 2021 YSC Finalist Presentation: Sidney Zhang',
                    description:
                      'Meet Sydney. Sydney developed a low-cost, high-sensitivity method for early diagnosis of dry eye disease (DED).',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '5aea3348-9700-4f32-8068-30a0ac3a35d9',
                    poster: '2021-finalist-thumb-veda-murphy.png',
                    title: '3M 2021 YSC Finalist Presentation: Veda Murphy',
                    description:
                      'Meet Veda. Veda developed the Cognitive Emotion Interpretation Application (CEIA) that helps teachers and caregivers interpret the emotions an Autism Spectrum Disorder (ASD) child is expressing.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'dea8cf85-9f70-491a-9749-df79ded44ee9',
                    poster: '2021-finalist-thumb-viraj-panday.png',
                    title: '3M 2021 YSC Finalist Presentation: Viraj Pandey',
                    description:
                      'Meet Viraj. Viraj synthesized and innovatively applied graphene quantum dots (GQDs) to help track endangered species.',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../3M-2020-YSC-Finalist-Presentations" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <p className="text-26">
              Hear from the <strong>2021 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2021;
